export const fallphotos = [
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0264.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0265.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0281.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0290.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0293.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0295.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0299.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0302.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0313.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0320.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0324.jpg",
    width: 4,
    height: 3,
  },
]
