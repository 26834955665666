import React from "react"
import Header from "../../components/header/header"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageGallery from "../../components/gallery/gallery-component"
import { fallphotos } from "../../components/photos/fall-photos"
import "../../../styles/global-styles.scss"

const FallPhotoShoot = () => (
  <Layout>
    <Header />
    <SEO
      title="Thanksgiving Lunch"
      description="Thanksgiving planning can be stressful, but a holiday party for your employees is one way to increase motivation and employee engagement. Our holiday parties are a great way to thank your employees and boost engagement. Holiday parties are also great over the holidays with friends and family."
    />
    <h1 className="gallery-photos-heading">Thanksgiving Lunch</h1>
    <p className="gallery-photos-text-body">
      Thanksgiving planning can be stressful, but a holiday party for your
      employees is one way to increase motivation and employee engagement. Our
      holiday parties are a great way to thank your employees and boost
      engagement. Holiday parties are also great over the holidays with friends
      and family.
    </p>
    <ImageGallery photos={fallphotos} />
  </Layout>
)

export default FallPhotoShoot
